<template>
  <div>
    <div
      class="card p-1"
      @click="showModal"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="card-cradit-blue p-1">
            <label
              class="h6 m-0 text-white"
            ><i class="fas fa-users" /> ออนไลน์วันนี้</label>
            <h3 class="text-white">
              {{ UserActive }} คน
            </h3>

            <small class="m-0">
              <i class="fas fa-arrow-up" /> -% ข้อมูลตามช่วงเวลา
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหาผู้ใช้งาน
          </h2>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="searchType"
                :options="searchlist"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="searchKeyword"
                :disabled="!searchType"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="username">สถานะฝาก/ถอน</label>
              <b-form-select
                v-model="searchStatus"
                :options="statusList"
                placeholder="เลือกสถานะ"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="dateStart">เวลาเริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="dateEnd">เวลาสิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="btn btn-gradient-dark"
              @click="GetList()"
            >
              ค้นหาผู้ใช้งาน
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-1">
        <div class="row">
          <div class="col d-flex align-items-center">
            <h3 class="text-dark font-medium-2">
              <i class="far fa-clipboard-user" />
              รายงานข้อมูลลูกค้าทั้งหมดของฉัน ({{ totalRows }} คน)
            </h3>
          </div>
        </div>
      </div>
      <b-table
        hover
        show-empty
        responsive
        :items="items"
        :fields="fields"
      >
        <template #cell(id)="data">
          <router-link :to="{ name: '' }">
            {{ data.item.id }}
          </router-link>
        </template>

        <template #cell(depfirst_amount)="data">
          {{ data.item.depfirst_amount ? data.item.depfirst_amount : "0.00" }}
        </template>
        <template #cell(created_at)="data">
          ( {{ Time(data.item.created_at) }} ) {{ data.item.created_at }}
        </template>

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>

        <template #cell(action)="">
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
            >
              <i class="fa fa-edit" />
            </b-button>
            <b-button
              variant="info"
              class="mr-1"
              size="sm"
            >
              เพื่อนที่แนะนำ
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-body
        class="d-flex justify-content-between flex-wrap pt-0 pl-1 pb-1"
      >
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetList()"
          />
        </b-form-group>

        <!-- pagination -->
        <div class="pr-1 pt-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetList()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>
    <b-modal
      ref="my-modal"
      hide-footer
      centered
      title="ภาพรวมรายการจาก AGENT แยกตามผู้แนะนำ"
      size="lg"
    >
      <b-overlay
        :show="show"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <b-icon-controller
              font-scale="3"
              animation="cylon"
            />
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <b-button
          variant="info"
          class="mr-1"
          size="sm"
          @click="GetModalAll(true)"
        >
          เดือนที่แล้ว
        </b-button>
        <b-button
          variant="info"
          class="mr-1"
          size="sm"
          @click="GetModalAll(false)"
        >
          เดือนปัจจุบัน
        </b-button>
        <div style="overflow-x: auto">
          <table
            class="table table-bordered"
            style="background-color: #fff; border: 1px solid #dee2e6; width: 100%;"
          >
            <colgroup>
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
            </colgroup>
            <thead
              class="text-center"
            >
              <tr>
                <th>วันที่</th>
                <th>ออนไลน์/ยูส</th>
                <th>ฝาก</th>
                <th>ถอน</th>
                <th>สรุป</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="item in ModalData"
                :key="item.date"
              >
                <td>
                  {{ item.date }}
                </td>
                <td>
                  {{ item.count }}
                </td>
                <td>
                  {{ item.SumDep ? item.SumDep : "0.00" }}
                </td>
                <td>
                  {{ item.SumWit ? item.SumWit : "0.00" }}
                </td>
                <td>
                  {{ item.SumTotal ? item.SumTotal : "0.00" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BFormSelect,
  BFormGroup,
  BPagination,
  BModal,
  BCardBody,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import momenttz from 'moment-timezone'
import TimeAgo from 'javascript-time-ago'

// English.
// eslint-disable-next-line import/extensions
import th from 'javascript-time-ago/locale/th'

export default {
  components: {
    BButton,
    BTable,
    BFormSelect,
    BFormGroup,
    BPagination,
    flatPickr,
    BCardBody,
    BModal,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      message: 'http://testing.power100.net/sigup?ref=123456s',
      searchBy: 'name',
      searchByOptions: [
        { value: 'name', text: 'ชื่อ' },
        { value: 'email', text: 'อีเมล' },
        { value: 'phone', text: 'เบอร์โทร' },
      ],
      fields: [
        { key: 'id', label: 'id' },
        { key: 'names', label: 'ผู้ใช้งาน' },
        { key: 'tel', label: 'เบอร์โทรศัพท์' },
        {
          key: 'created_at',
          label: 'สมัครเมื่อ',
          formatter: value => (value ? momenttz(value).format('DD/MM/YYYY HH:mm:ss') : '-'),
        },
        {
          key: 'depfirst_date',
          label: 'ฝากครั้งแรก',
          formatter: value => (value
            ? momenttz(value).add('hours', -7).format('DD/MM/YYYY HH:mm:ss')
            : 'ไม่ฝาก'),
        },
        { key: 'depfirst_amount', label: 'ยอดฝากแรก' },
      ],
      statusList: [
        { value: null, text: 'ทั้งหมด' },
        { value: 0, text: 'ไม่ฝาก' },
        { value: 1, text: 'ฝาก' },
      ],
      items: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      searchType: null,
      searchStatus: null,
      searchKeyword: null,
      dateStart: null,
      dateEnd: null,
      searchlist: [
        { value: 1, text: 'ชื่อ' },
        { value: 2, text: 'ยูสเซอร์เนม' },
        { value: 3, text: 'เบอร์โทร' },
      ],
      UserActive: 0,
      ModalData: [],
      show: false,
    }
  },
  mounted() {
    this.GetList()
    this.GetUserActive()
    this.GetModalAll()
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    Time(data) {
      TimeAgo.addDefaultLocale(th)
      const timeAgo = new TimeAgo('th-TH')
      const time = timeAgo.format(new Date(data))
      return time
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกลิ้งค์สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    GetModalAll(monthago) {
      this.show = true
      const params = {
        invite_code: this.$route.params.id,
        monthago,
      }
      this.$http
        .post('/zean/GetOnlineDetail', params)
        .then(response => {
          this.ModalData = response.data
          this.show = false
        })
        .catch(error => console.log(error))
    },
    GetUserActive() {
      const params = {
        invite_code: this.$route.params.id,
      }
      this.$http
        .post('/zean/UserActive', params)
        .then(response => {
          this.UserActive = response.data.total
        })
        .catch(error => console.log(error))
    },
    GetList() {
      const params = {
        invite_code: this.$route.params.id,
        page: this.currentPage,
        perPage: this.perPage,
        searchType: this.searchType,
        searchStatus: this.searchStatus,
        searchKeyword: this.searchKeyword,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('/zean/detail/ALL', params)
        .then(response => {
          this.items = response.data
          this.onFiltered(response.data)
          this.RowColor()
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    RowColor() {
      this.items.forEach((item, index) => {
        this.items[index].created_at = momenttz(item.created_at).add('hours', 7).format('YYYY-MM-DD HH:mm:ss')
        if (item.depfirst_date === null) {
          // eslint-disable-next-line no-underscore-dangle
          this.items[index]._rowVariant = 'warning'
        }
      })
    },
  },
}
</script>

<style scoped>
.darker {
  border-radius: 0;
  color: #ffffff !important;
  background: #23292e !important;
}
.bluer {
  border-radius: 0;
  color: #ffffff !important;
  background: #2e5788 !important;
}
.bluesea {
  border-radius: 0;
  color: #ffffff !important;
  background: #1f91fc !important;
}
.orange {
  border-radius: 0;
  color: #ffffff !important;
  background: #ef9b55 !important;
}
.green {
  border-radius: 0;
  color: #ffffff !important;
  background: #5cc765 !important;
}
th {
  background: #003866 !important;
  color: #ffffff !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
